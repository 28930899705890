import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

const SectionHead = ({ title, description, supDescription, color }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div>
            <Box textAlign={'center'} my={3} >
                <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ color: color, fontWeight: 'bold', mb: 2 }} >
                    {title}
                </Typography>
                <Typography sx={{ color: '#939494', lineHeight: '1.8', }}>
                    {description}
                </Typography>
                <Typography sx={{ color: '#939494', lineHeight: '1.8', }}>
                    {supDescription}
                </Typography>
            </Box>
        </div>
    )
}

export default SectionHead