import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import { useTranslation } from "react-i18next";
import TransLang from "./TransLang"
import logo from './../../assets/logo.png'
import './Nav.css'
function NavBar() {
    const { t } = useTranslation()
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: "#10446F",
                    padding: "10px"
                }}>
                <>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 5,
                                marginInlineStart: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'space-around'
                            }}>
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    marginInlineStart: 8
                                }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} >
                                    <img src={logo} height={60} alt="logo" className="nav-logo" ></img>
                                    <Box>
                                        <Typography sx={{ color: '#fff', fontWeight: 'bold' }} >ارض الصفاء للخدمات الفنية</Typography>
                                        <Typography sx={{ textTransform: 'capitalize', color: '#fff', fontWeight: 'bold' }} >ard alsafa technical services</Typography>
                                    </Box>
                                </Box>
                            </Link>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    width: '60%'
                                }}
                            >
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 14,
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}
                                    className="link"
                                >
                                    {t('linksPages.home')}
                                </Link>
                                <Link
                                    to="/introduction"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 14,
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}
                                    className="link"
                                >
                                    {t('linksPages.Introduction')}
                                </Link>
                                <Link
                                    to="/our-services"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 14,
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}
                                    className="link"
                                >
                                    {t('linksPages.OurServices')}
                                </Link>
                                <Link
                                    to="/project-history"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 14,
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}
                                    className="link"
                                >
                                    {t('linksPages.ProjectHistory')}
                                </Link>
                                <Link
                                    to="/contacts-us"
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 14,
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold'
                                    }}
                                    className="link"
                                >
                                    {t('linksPages.ContactUs')}
                                </Link>
                                <TransLang />
                            </Box>
                        </Box>
                        <Box sx={{ justifyContent: 'space-between', display: 'flex', gap: { md: '65px', xs: 1 }, width: { md: 'fit-content', xs: '100%' }, alignItems: 'center' }} >
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}>
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        marginInlineStart: 8
                                    }}>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} >
                                        <img src={logo} height={60} alt="logo" className="nav-logo" ></img>
                                        <Box>
                                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }} >ارض الصفاء للخدمات الفنية</Typography>
                                            <Typography sx={{ textTransform: 'capitalize', color: '#fff', fontWeight: 'bold' }} >ard alsafa technical services</Typography>
                                        </Box>
                                    </Box>
                                </Link>
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ fontSize: '3rem', color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
