import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import introduction from './../../assets/Introduction/23-8-23-33.png'
import { useTranslation } from 'react-i18next'
const Introduction = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <Box sx={{ display: 'flex', flexWrap: 'wrap-reverse', gap: 2, my: 3, justifyContent: 'center', alignItems: 'center' }} >
                    <Box sx={{ width: { md: '48%', xs: '100%' } }} >
                        <Typography variant='h4' sx={{ color: '#10446F', fontWeight: 'bold', mb: 2 }} >
                            {t("WhoWeAre.Introduction")}
                        </Typography>
                        <Typography sx={{ color: '#939494', lineHeight: '1.8', }}>
                            {t("WhoWeAre.IntroductionDesc")}
                        </Typography>
                    </Box>
                    <Box sx={{ width: { md: '48%', xs: '100%' } }} >
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {/* الخط العلوي */}
                            <div style={{
                                position: 'absolute',
                                top: -10,
                                right: 0,
                                width: '50%',
                                borderBottom: '2px solid #10446F',
                                // margin: '10px 0'
                            }} />

                            {/* الخط السفلي */}
                            <div style={{
                                position: 'absolute',
                                bottom: -10,
                                left: 0,
                                width: '50%',
                                borderTop: '2px solid #10446F',
                                // margin: '10px 0'
                            }} />

                            {/* الصورة */}
                            <CardMedia
                                className='line'
                                component={'img'}
                                src={introduction}
                                sx={{ width: '100%', height: '100%', my: 2 }}
                            />
                        </div>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default Introduction