import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const CardService = ({ serviceImg, title }) => {
    return (
        <div>
            <Box sx={{
                textAlign: 'center', m: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                alignItems: 'center'
            }} >
                <div style={{
                    transform: 'rotate(45deg)',
                    display: 'inline-block'
                }}>
                    <CardMedia
                        component={'img'}
                        src={serviceImg}
                        sx={{
                            objectFit: 'cover',
                            width: '170px',
                            height: '170px',
                            transition: '1.2s',
                            "&:hover": {
                                transform: 'rotate(360deg)'
                            },
                            // transform: 'rotate(45deg)'
                        }} />
                </div>
                <Typography
                    // variant='h6'
                    sx={{ color: '#10446F', fontWeight: 'bold', my: 1 }} >
                    {title}
                </Typography>
            </Box>
        </div>
    )
}

export default CardService