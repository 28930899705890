import React from 'react'
import Header from '../components/glopal/Header'
import { Container } from '@mui/material'
import Service from '../components/Service/Service'
import { useTranslation } from 'react-i18next'

const OurServices = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Header
                title={t("Service.OurService")}
                url={t("Service.OurService")}
            />
            <Container>
                <Service />
            </Container>
        </div>
    )
}

export default OurServices