import React from 'react'
import ListValues from './ListValues'
import { Container } from '@mui/material'
import SectionHead from '../glopal/SictionHead'
import { useTranslation } from 'react-i18next'

const OurValues = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <SectionHead
                    color={`#10446F`}
                    title={t("OurValues.title")}
                />
                <ListValues />
            </Container>
        </div>
    )
}

export default OurValues