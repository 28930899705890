import { Container } from '@mui/material'
import React from 'react'
import SectionHead from '../glopal/SictionHead'
import ListServices from './ListServices'
import { useTranslation } from 'react-i18next'

const Service = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <SectionHead
                    color={'#10446F'}
                    title={t("Service.title")}
                    description={t("Service.description")}
                    supDescription={t("Service.supDescription")} />
                <ListServices />
            </Container>
        </div>
    )
}

export default Service