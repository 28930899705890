import React from 'react'
import Header from '../components/glopal/Header'
import ListContactCard from '../components/contact/ListContactCard'
import SectionHead from '../components/glopal/SictionHead'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
    const { t } = useTranslation()
    return (
        <div >
            <Header
                title={(t("ContactUs"))}
                url={(t("ContactUs"))}
            />
            <SectionHead
                color={`#10446F`}
                title={(t("ContactUs"))}
            />
            <ListContactCard />
        </div>
    )
}

export default ContactUs