import { Box, Typography } from '@mui/material'
import React from 'react'
import logo from './../../assets/logo.png'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Box sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: "#10446F", flexWrap: 'wrap',
                px: 8,
                py: 2,
                textAlign: { md: 'inherit', xs: 'center' },
            }} >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} >
                    <img src={logo} height={60} alt="logo" className="nav-logo" ></img>
                    <Box>
                        <Typography sx={{ color: '#fff', fontWeight: 'bold' }} >ارض الصفاء للخدمات الفنية</Typography>
                        <Typography sx={{ textTransform: 'capitalize', color: '#fff', fontWeight: 'bold' }} >ard alsafa technical services</Typography>
                    </Box>
                </Box>
                <Box>
                    <a href="https://revampbrands.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#FFF', textDecoration: 'none' }}
                    >
                        <Typography>{t("copeRight")}</Typography>
                    </a>
                </Box>
            </Box>
        </div>
    )
}

export default Footer