import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TransLang from "./TransLang"

export default function NavDrawer({ setDrawer, drawer }) {
    const { t } = useTranslation()
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}>
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                        }}>
                        <Link
                            to="/"
                            style={{
                                textDecoration: "none",
                                fontSize: 14,
                                color: 'inherit',
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }}
                            className="link"
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.home')}
                        </Link>
                        <Link
                            to="/introduction"
                            style={{
                                textDecoration: "none",
                                fontSize: 14,
                                color: 'inherit',
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }}
                            className="link"
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.Introduction')}
                        </Link>
                        <Link
                            to="/our-services"
                            style={{
                                textDecoration: "none",
                                fontSize: 14,
                                color: 'inherit',
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }}
                            className="link"
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.OurServices')}
                        </Link>
                        <Link
                            to="/project-history"
                            style={{
                                textDecoration: "none",
                                fontSize: 14,
                                color: 'inherit',
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }}
                            className="link"
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.ProjectHistory')}
                        </Link>
                        <Link
                            to="/contacts-us"
                            style={{
                                textDecoration: "none",
                                fontSize: 14,
                                color: 'inherit',
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }}
                            className="link"
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.ContactUs')}
                        </Link>
                        <TransLang />
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
