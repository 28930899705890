import { Grid } from '@mui/material'
import React from 'react'
import CardValues from './CardValues'
import { values } from '../../constants'
import i18next from 'i18next'

const ListValues = () => {
    let lng = i18next.language
    return (
        <div>
            <Grid container spacing={2} >
                {
                    values.map((value) =>
                        <Grid kay={value.id} item md={4} xs={12}>
                            <CardValues
                                valueImg={value.imageUrl}
                                title={lng === 'en' ? value?.name : value?.nameAr}
                                description={lng === 'en' ? value?.supName : value?.supNameAr}

                            />
                        </Grid>
                    )}
            </Grid>
        </div>
    )
}

export default ListValues