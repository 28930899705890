import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import imgMission from './../../assets/Introduction/our_mission_page_352980-Recovered.png'
import { useTranslation } from 'react-i18next'
const OurMission = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, my: 3, justifyContent: 'space-between', alignItems: 'center' }} >
                    <Box sx={{ width: { md: '48%', xs: '100%' } }} >

                        <CardMedia
                            className='line'
                            component={'img'}
                            src={imgMission}
                            sx={{ width: '100%', height: '100%', my: 2 }}
                        />
                    </Box>
                    <Box sx={{ width: { md: '48%', xs: '100%' } }} >
                        <Typography variant='h4' sx={{ color: '#10446F', fontWeight: 'bold', mb: 2 }} >
                            {t("WhoWeAre.OurMission")}
                        </Typography>
                        <Typography sx={{ color: '#939494', lineHeight: '1.8', }}>
                            {t("WhoWeAre.OurMissionDesc")}
                        </Typography>
                    </Box>

                </Box>
            </Container>
        </div>
    )
}

export default OurMission