import React from 'react'
import './contact.css'
import facebook from './../../assets/contacts/Icon awesome-facebook-f.png' 
import instagram from './../../assets/contacts/Icon awesome-instagram.png'
import tiktok from './../../assets/contacts/Icon simple-tiktok.png'
import i18next from 'i18next'
const SocialContact = () => {
    let lng = i18next.language
    return (
        <div>
            <nav class={lng === 'en' ? "social" : "socialAr"}> 
                <ul>
                    <li><a href="https://www.facebook.com/Ard.Alsafa/">facebook
                        <img src={facebook} alt="icon" style={{ background: '#1976D2' }} />
                    </a></li>
                    <li><a href="https://www.instagram.com/ardalsafa7/">instagram
                        <img src={instagram} alt="icon" style={{ background: '#F84E51' }} />
                    </a></li>
                    <li><a href="https://www.tiktok.com/@ardalsafa1">
                        tiktok
                        <img src={tiktok} alt="icon" style={{ background: '#000' }} />
                    </a></li>
                </ul>
            </nav>
        </div>
    )
}

export default SocialContact