
import { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ScrollTo from './components/Ui/ScrollToTop';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import Footer from './components/footer/Footer';
import OurServices from './pages/OurServices';
import Projects from './pages/Projects';
import ContactUs from './pages/ContactUs';
import WhoWeAre from './pages/Introduction';

const languages = [
  {
    code: 'ar',
    name: 'عربي',
    country_coode: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'English',
    country_coode: 'gb',
    dir: 'ltr'
  }
]

function App() {

  const currentLanguageCode = Cookies.get('i18next') || 'ar';
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
  const { t } = useTranslation();
  useEffect(() => {

    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');
    // document.title = t('app_title'); 

  }, [currentLanguage, t,]);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <div className="App">
      <CssBaseline />
      <NavBar />
      <ScrollToTop />
      <ScrollTo />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/introduction" element={<WhoWeAre />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/project-history" element={<Projects />} />
        <Route path="/contacts-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
