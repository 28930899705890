import React from 'react'
import Header from '../components/glopal/Header'
import Introduction from '../components/Introduction/Introduction'
import OurGuidelines from '../components/Introduction/OurGuidelines'
import OurMission from '../components/Introduction/OurMission'
import OurValues from '../components/OurValues/OurValues'
import OurVision from '../components/Introduction/OurVision'
import { useTranslation } from 'react-i18next'

const WhoWeAre = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Header
                title={t("WhoWeAre.title")}
                url={t("WhoWeAre.title")}
            />
            <Introduction />
            <OurGuidelines />
            <OurMission />
            <OurValues />
            <OurVision />
        </div>
    )
}

export default WhoWeAre