import React from 'react'
import CardService from './CardService'
import { Box } from '@mui/material'
import { services } from '../../constants'

const ListServices = () => {
    return (
        <div>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap', gap: 3.2,
                justifyContent: 'center'
            }} >
                {
                    services.map((service) =>
                        <CardService
                            serviceImg={service.imageUrl} title={service.title} />
                    )}
            </Box>
        </div>
    )
}

export default ListServices