import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import vision from './../../assets/Introduction/23-8-23-12.png'
import vision1 from './../../assets/Introduction/55.png'
import vision2 from './../../assets/Introduction/23-8-23-9.png'
import vision3 from './../../assets/Introduction/building-crane-buildings-construction.png'
import { useTranslation } from 'react-i18next'

const OurVision = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <Box sx={{ display: 'flex', flexWrap: 'wrap-reverse', gap: 2, my: 3, justifyContent: 'center', alignItems: 'center' }} >
                    <Box sx={{ width: { md: '48%', xs: '100%' }, height: '100%' }} >
                        <Typography variant='h4' sx={{ color: '#10446F', fontWeight: 'bold', mb: 2 }} >
                            {t("WhoWeAre.OurVision")}
                        </Typography>
                        <Typography sx={{ color: '#939494', lineHeight: '2', my: 3 }}>
                            {t("WhoWeAre.OurVisionDesc1")}
                        </Typography>
                        <Typography sx={{ color: '#939494', lineHeight: '2', my: 3 }}>
                            {t("WhoWeAre.OurVisionDesc2")}
                        </Typography>
                        <Typography sx={{ color: '#939494', lineHeight: '2', my: 3 }}>
                            {t("WhoWeAre.OurVisionDesc3")}
                        </Typography>
                    </Box>
                    <Box sx={{ width: { md: '48%', xs: '100%' } }} >
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {/* الخط العلوي */}
                            <div style={{
                                position: 'absolute',
                                top: -10,
                                right: 0,
                                width: '50%',
                                borderBottom: '2px solid #10446F',
                                // margin: '10px 0'
                            }} />
                            {/* الصورة */}
                            <CardMedia
                                className='line'
                                component={'img'}
                                src={vision}
                                sx={{ width: '100%', height: '100%', my: 2 }}
                            />
                            <Box sx={{ display: { md: 'flex', xs: 'none' }, gap: 2, justifyContent: 'space-between' }} >
                                <CardMedia
                                    className='line'
                                    component={'img'}
                                    src={vision3}
                                    sx={{ width: '175px', height: '100%', my: 2 }}
                                />
                                <CardMedia
                                    className='line'
                                    component={'img'}
                                    src={vision2}
                                    sx={{ width: '175px', height: '100%', my: 2 }}
                                />
                                <CardMedia
                                    className='line'
                                    component={'img'}
                                    src={vision1}
                                    sx={{ width: '175px', height: '100%', my: 2 }}
                                />
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default OurVision