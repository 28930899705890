import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const CardValues = ({ kay, valueImg, title, description }) => {
    return (

        <Box
            kay={kay}
            sx={{
                textAlign: 'center',
                p: 2,
                borderRadius: '0 30px 0 30px',
                border: '1px solid #10446F',
                height: '100%'
            }} >
            <CardMedia
                component={'img'}
                src={valueImg}
                sx={{
                    p: 2,
                    width: '75px',
                    height: '75px',
                    borderRadius: '8px',
                    margin: 'auto',
                    background: '#10446F'
                }} />
            <Typography variant='h5' sx={{
                color: '#10446F',
                fontWeight: 'bold',
                my: 2
            }} >
                {title}
            </Typography>
            <Typography sx={{
                color: '#1E6FA1',
                lineHeight: '1.8',
            }}>
                {description}
            </Typography>
        </Box>

    )
}

export default CardValues