import React from 'react'
import './OurGuidelines.css'
import { Box, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
const OurGuidelines = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className='bgImage' >
                <div className="overlay"></div>
                <Container sx={{ zIndex: 99 }} >
                    <Box textAlign={'center'} my={3} >
                        <Typography variant='h4' sx={{ color: '#fff', fontWeight: 'bold', mb: 2 }} >
                            {t("WhoWeAre.OurGuidelines")}
                        </Typography>
                        <Typography sx={{ lineHeight: '1.8', }}>
                            <ul className='list'>
                                <li> {t("WhoWeAre.OurGuidelines1")}</li>
                                <li>{t("WhoWeAre.OurGuidelines2")}</li>
                                <li> {t("WhoWeAre.OurGuidelines3")}</li>
                                <li> {t("WhoWeAre.OurGuidelines4")}</li>
                                <li>{t("WhoWeAre.OurGuidelines5")}</li>
                            </ul>
                        </Typography>
                    </Box>
                </Container>
            </div>
        </div>
    )
}

export default OurGuidelines