import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
export default function BasicSelect() {
    const lngs = {
        en: { nativeName: 'English' },
        ar: { nativeName: 'Arabic' }
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { i18n } = useTranslation()
    let lng = i18next.language
    return (
        <div>
            <Box textAlign={'end'} width='fit-content' >
                <Typography
                    sx={{
                        cursor: "pointer",
                        px: 3,
                        border: { md: 'none', xs: '1px solid #707070' },
                        boxShadow: 'inset 105px 12px 2px 1px rgba(255,255,255,.2)',
                        color: { md: '#FFF', xs: '#000' },
                    }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {lng === 'en' ? 'English' : 'العربية'}
                </Typography>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}>
                    {Object.keys(lngs).map((lng) => (
                        <MenuItem key={lng}
                            style={{
                                fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
                                zIndex: 99999999999999999999999999999999999999999999999999999,
                            }}
                            type="submit"
                            value={lng}
                            onClick={() => { i18n.changeLanguage(lng); handleClose() }}>
                            <Typography>
                                {lng === 'en' ? 'English' : 'العربية'}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </div>
    );
}