import { Box, Button, CardMedia, Container, Grid } from '@mui/material'
import React from 'react'
import SectionHead from '../glopal/SictionHead'
import { projects1, projects2, projects3 } from '../../constants'
import { useTranslation } from 'react-i18next'
import pd from "./../../assets/new.pdf"
import SectionProject from '../SectionProject/SectionProject'
const OurProjects = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Container>
                <SectionHead
                    color={`#10446F`}
                    title={t("OurProjects.title")} />
            </Container>
            <SectionProject title={t("OurProjects.Proj1")} >
                <Grid container  >
                    {
                        projects1.map((project) =>
                            <Grid item md={4} xs={12}>
                                <CardMedia
                                    component={'img'}
                                    src={project.imageUrl}
                                    sx={{
                                        objectFit: 'cover',
                                        width: '90%',
                                        height: '50vh',
                                        margin: 'auto'
                                    }} />
                            </Grid>
                        )}
                </Grid>
            </SectionProject>
            <SectionProject title={t("OurProjects.Proj2")} >
                <Grid container  >
                    {
                        projects2.map((project) =>
                            <Grid item md={4} xs={12}>
                                <CardMedia
                                    component={'img'}
                                    src={project.imageUrl}
                                    sx={{
                                        objectFit: 'cover',
                                        width: '90%',
                                        height: '50vh',
                                        margin: 'auto'
                                    }} />
                            </Grid>
                        )}
                </Grid>
            </SectionProject>
            <SectionProject title={t("OurProjects.Proj3")} >
                <Grid container  >
                    {
                        projects3.map((project) =>
                            <Grid item md={4} xs={12}>
                                <CardMedia
                                    component={'img'}
                                    src={project.imageUrl}
                                    sx={{
                                        objectFit: 'cover',
                                        width: '90%',
                                        height: '50vh',
                                        margin: 'auto'
                                    }} />
                            </Grid>
                        )}
                </Grid>
            </SectionProject>
            <Box sx={{ my: 2, textAlign: 'center' }} >
                <a href={pd}
                    target="_blank" rel="noopener noreferrer">

                    <Button sx={{
                        backgroundColor: '#1E6FA1',
                        color: '#fff',
                        "&:hover": {
                            backgroundColor: '#1E6FA1', color: "#fff",
                        },
                    }} >
                        {t("Projects.SeeMore")}
                    </Button>
                </a>
            </Box>
        </div>
    )
}

export default OurProjects