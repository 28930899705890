import React from 'react'
import { Box, Button, CardMedia, Typography } from '@mui/material';
import Carousel from "react-material-ui-carousel";
import header1 from './../../assets/home/slider/headN1.png'
import header3 from './../../assets/home/slider/s2.jpg'
import header4 from './../../assets/home/slider/s3.jpg'
import header5 from './../../assets/home/slider/s4.jpg'
import './Header.css'
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation()
    return (
        <>
            <Box position={'relative'} >
                <div className="overlay"></div>
                <Carousel
                    indicators={true}
                    indicatorContainerStyle={{ position: 'absolute', bottom: '50px', zIndex: '9999' }}
                    sx={{
                        width: "100%",
                        height: { md: "85vh", xs: "300px" },
                        margin: "auto"
                    }}>
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header1}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header3}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header4}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header5}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                </Carousel>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '5%',
                    padding: 2,
                    transform: 'translate(-5%, -50%)', zIndex: 999,
                    display: { md: 'inherit', xs: 'none' }
                }} >
                    <Typography variant='h5' sx={{ color: '#fff', fontWeight: '500', my: 1 }}>
                        {t("sliderText.Welcome")} <br />
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', color: '#fff' }} variant='h4' >
                        {t("sliderText.ArdAlsafa")}
                    </Typography> <br />
                    <Button sx={{
                        backgroundColor: '#fff', color: "#1E6FA1",
                        "&:hover": {
                            backgroundColor: '#1E6FA1',
                            color: '#fff'
                        },
                    }} >
                        {t("sliderText.ReadMore")}
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default Header
