import { Container } from '@mui/material'
import React from 'react'
import SectionHead from '../glopal/SictionHead'
import './UrbanDevelopment.css'
import { useTranslation } from 'react-i18next'
const UrbanDevelopment = () => {
    const { t } = useTranslation()
    return (
        <div className='bgImage' >
            <div className="overlay"></div>
            <Container sx={{ zIndex: 99, width: { md: '53%', xs: '90%' } }} >
                <SectionHead
                    color={`#fff`}
                    title={t("UrbanDevelopment.title")}
                    description={t("UrbanDevelopment.description")}
                    supDescription={t("UrbanDevelopment.supDescription")} />
            </Container>
        </div>
    )
}

export default UrbanDevelopment