import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

const SectionProject = ({ title, children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div>
            <Box>
                <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ color: '#10446F', fontWeight: 'bold', m: 4 }} >{title}</Typography>
                {children}
            </Box>
        </div>
    )
}

export default SectionProject