import React from 'react'
import Header from '../components/Header/Header'
import AboutUs from '../components/AboutUs/AboutUs'
import Service from '../components/Service/Service'
import UrbanDevelopment from '../components/UrbanDevelopment/UrbanDevelopment'
import OurValues from '../components/OurValues/OurValues'
import OurProjects from '../components/OurProjects/OurProjects'

const Home = () => {
    return (
        <div>
            <Header />
            <AboutUs />
            <Service />
            <UrbanDevelopment />
            <OurValues />
            <OurProjects />
        </div>
    )
}

export default Home