import React from 'react'
import './Header.css'
import { Box, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
const Header = ({ title, url }) => {
    const { t } = useTranslation()

    return (
        <div className='header' >
            <div className="overlay"></div>
            <Box sx={{ position: 'relative', zIndex: '9' }} >
                <Typography variant='h4' sx={{ color: "#fff", fontWeight: 'bold', mb: 2 }} >
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', color: '#FFF', justifyContent: 'center', gap: 1 }} >
                    <Typography>{t('linksPages.home')}</Typography>
                    <EastIcon sx={{ color: '#7FCAE7' }} />
                    <Typography>{url}</Typography>
                </Box>
            </Box>
        </div>
    )
}

export default Header