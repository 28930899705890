import { Grid } from '@mui/material'
import React from 'react'
import CardContact from './CardContact'
import { contacts } from '../../constants'
import './contact.css'
import { Container } from '@mui/material'
import SocialContact from './SocialContact'

const ListContactCard = () => {
    return (
        <div>
            <Container sx={{ m: '5% auto', }} >
                <Grid container spacing={{ md: 2, xs: 1 }} sx={{ mb: '-10%', position: 'relative', zIndex: 9 }} >
                    {
                        contacts.map((contact) =>
                            <Grid item md={4} xs={12}>
                                <CardContact
                                    kay={contact.id}
                                    contactImg={contact.imageUrl}
                                    title={contact.name}
                                    description={contact.supName}
                                    supDescription={contact?.desc}
                                />
                            </Grid>
                        )}
                </Grid>
            </Container>
            <div className="bg-contact">
                <SocialContact />
            </div>
        </div>
    )
}

export default ListContactCard