import { Container } from '@mui/material'
import React from 'react'
import SectionHead from '../glopal/SictionHead'
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Container>
                <SectionHead
                    color={'#10446F'}
                    title={t("AboutUs.TECHNICALSERVICESTitle")}
                    description={t("AboutUs.TECHNICALSERVICESDesc")} />
            </Container>
        </div>
    )
}

export default AboutUs