import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const CardContact = ({ kay, contactImg, title, description, supDescription }) => {
    return (
        <>
            <Box
                kay={kay}
                sx={{
                    textAlign: 'center',
                    p: 2,
                    borderRadius: '0 30px 0 30px',
                    border: '1px solid #10446F',
                    height: '100%',
                    background: '#fff',
                }} >
                <CardMedia
                    component={'img'}
                    src={contactImg}
                    sx={{
                        // p: 2,
                        width: '75px',
                        height: '75px',
                        borderRadius: '8px',
                        margin: 'auto',
                        objectFit: 'contain',
                        // marginTop: { md: 0, xs: 0 }
                    }} />
                <Typography variant='h5' sx={{
                    color: '#10446F',
                    fontWeight: 'bold',
                    my: 2
                }} >
                    {title}
                </Typography>
                <Typography sx={{
                    color: '#1E6FA1',
                    lineHeight: '1.8',
                }}>
                    {description}
                </Typography>
                <Typography sx={{
                    color: '#1E6FA1',
                    lineHeight: '1.8',
                }}>
                    {supDescription}
                </Typography>
            </Box>
        </>
    )
}

export default CardContact