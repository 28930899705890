
import one from './../assets/home/values/security.png'
import tow from './../assets/home/values/strategy.png'
import three from './../assets/home/values/passion.png'
import four from './../assets/home/values/integration (1).png'
import five from './../assets/home/values/friends.png'
import six from './../assets/home/values/teamwork.png'

import s1 from './../assets/services/23-8-23-12.png'
import s2 from './../assets/services/23-8-23-13.png'
import s3 from './../assets/services/23-8-23-14.png'
import s4 from './../assets/services/23-8-23-15.png'
import s5 from './../assets/services/23-8-23-17.png'
import s6 from './../assets/services/23-8-23-18.png'
import s7 from './../assets/services/WhatsApp Image 2023-12-19 at 10.14.58 AM.jpeg'
import s8 from './../assets/services/23-8-23-19.png'
import p4 from './../assets/projects/4.png'
import p5 from './../assets/projects/5.png'
import p7 from './../assets/projects/7.png'
import p8 from './../assets/projects/8.png'
import p11 from './../assets/projects/11.jpg'
import p18 from './../assets/projects/18.jpg'
import p17 from './../assets/projects/17.jpg'
import p10 from './../assets/projects/10.jpg'
import p9 from './../assets/projects/9.jpg'

import c1 from './../assets/contacts/Icon awesome-address-card.png'
import c2 from './../assets/contacts/Icon zocial-email.png'
import c3 from './../assets/contacts/Icon awesome-phone-square-alt.png'

export const values = [
        {
                id: 1,
                imageUrl: one,
                name: `Safety First`,
                supName: `We make the well-being of our people our top
 priority everywhere we work by nurturing
 a culture that lives and breathes safety`,
                nameAr: `السلامة أولا`,
                supNameAr: `نضع سلامة الأشخاص أولوية قصوى في كل مكان  نحن نعمل على تعزيز ثقافة الحياة الامنة والصحية `,
        },
        {
                id: 2,
                imageUrl: tow,
                name: `Strategically Thinks and Acts`,
                supName: `We have built an agile, responsive culture 
that focuses on our client’s needs and
 provides customized solutions with certainty 
of completion`,
                nameAr: `التفكير والعمل الاستراتيجي`,
                supNameAr: `لقد بنينا ثقافة رشيقة تركز على احتياجات عملائنا وتوفر الحلول المناسبة لاحتياجاتهم`,
        },
        {
                id: 3,
                imageUrl: three,
                name: `Passion`,
                supName: `Our passion is to bring new, innovative and
 sustainable ideas to our clients.`,
                nameAr: `شغف`,
                supNameAr: `شغفنا هو إحضار أفكار جديدة ومبتكرة ومستدامة لعملائنا `,
        },
        {
                id: 4,
                imageUrl: four,
                name: `Integrity, Honesty and Trust`,
                supName: `We are committed to upholding high ethical 
standards in all we do. We lead by example
 act responsibly to make a positive contribution
 to our communities.`,
                nameAr: `النزاهة والصدق والثقة`,
                supNameAr: ` نحن ملتزمون بالتمسك بالمعايير الأخلاقية العالية في كل ما نقوم به. إننا نعمل إلى بمسؤولية  للمساهمة بإيجابية في مجتمعاتنا.`,
        },
        {
                id: 5,
                imageUrl: five,
                name: `Right People, Right Job`,
                supName: `We create exciting opportunities for our employees, 
clients and communities by growing`,
                nameAr: `الشخص الصحيح للعمل الصحيح `,
                supNameAr: `نقوم بصناعة الفرص المذهلة لموظفينا وعملائنا مجتمعاتنا بتنميتها وازدهارها`,
        },
        {
                id: 6,
                imageUrl: six,
                name: `Teamwork`,
                supName: `We deliver a consistent, quality service, sharing
 knowledge across the nation and capturing valuable 
perspective`,
                nameAr: `عمل الفريق `,
                supNameAr: ` نحن نقدم خدمة ذات جودة عالية وتشارك المعرفة عبر الأمة ومن خلال  منظور القيم الأخلاقية`,
        },
];
export const contacts = [
        {
                id: 1,
                imageUrl: c1,
                name: `Address`,
                supName: `Office 48, near Dubai police General 
HQ,hor Al hanz East,, Dubai-UAE`,
                nameAr: `Address`,
                supNameAr: `Office 48, near Dubai police General 
HQ,hor Al hanz East,, Dubai-UAE`,
        },
        {
                id: 2,
                imageUrl: c2,
                name: `Email`,
                supName: `info@ardalsafa-eng.com`,
                nameAr: `Email`,
                supNameAr: `info@ardalsafa-eng.com`,
        },
        {
                id: 3,
                imageUrl: c3,
                name: `Phone`,
                supName: `+971566452930`,
                nameAr: `Phone`,
                supNameAr: `+971566452930`,
                desc: "+971 55 638 6323"
        },
];

export const services = [
        {
                id: 1,
                imageUrl: s1,
                title: `ENGINEERING`
        },
        {
                id: 2,
                imageUrl: s2,
                title: `PLUMBING`
        },
        {
                id: 3,
                imageUrl: s3,
                title: `ELECTRICAL`
        },
        {
                id: 4,
                imageUrl: s4,
                title: `MECHANICAL`
        },
        {
                id: 5,
                imageUrl: s5,
                title: `Audio system Hanical`
        },
        {
                id: 6,
                imageUrl: s6,
                title: `intercom`

        },
        {
                id: 7,
                imageUrl: s7,
                title: `CIVIL`

        },
        {
                id: 8,
                imageUrl: s8,
                title: `CCTV`

        },
];
export const projects1 = [
        {
                id: 1,
                imageUrl: p4,
        },
        {
                id: 2,
                imageUrl: p5,
        },
        {
                id: 3,
                imageUrl: p7,
        },

];
export const projects2 = [
        {
                id: 4,
                imageUrl: p11,
        },
        {
                id: 5,
                imageUrl: p10,
        },
        {
                id: 6,
                imageUrl: p9,
        },
];

export const projects3 = [

        {
                id: 7,
                imageUrl: p18,

        },
        {
                id: 8,
                imageUrl: p8,

        },
        {
                id: 7,
                imageUrl: p17,

        },

];

